import { Card } from "../types";
import Link from "next/link";
import styles from "../style.module.scss";
import Image from "next/image";
import { useContext } from "react";
import { AppContext } from "~/src/libs/context";
import classNames from "classnames";

const CategoryItem = ({
  cardImage,
  title,
  redirectUrl = "",
  cardType,
  backgroundColor,
  cardDescription = "",
  AllowedUsers
}: Card & {
  cardType?: string;
  AllowedUsers?: string;
}) => {
  const { guestUser } = useContext<any>(AppContext);
  return (
    <>
      {cardType === "top-categories" || cardType === "welcome-banner" ? (
        guestUser ||
        (!guestUser &&
          AllowedUsers === "Registered" &&
          cardType === "welcome-banner") ? (
          <Link href={redirectUrl} passHref>
            <a className={styles.CategoryItem}>
              <div>
                <div
                  style={{ backgroundImage: `url(${cardImage?.url})` }}
                  className={`${styles.CategoryImage} mb-12`}
                  key={title}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "relative"
                    }}
                  ></div>
                </div>
                <h2
                  className={`${styles.CardTitle} font-semibold-h5 lh-sm text-oxfordBlue text-center`}
                >
                  {title}
                </h2>
                {cardDescription !== "" && (
                  <p
                    className="font-regular-xs lh-lg text-center text-oxfordBlue"
                    style={{ maxWidth: "200px" }}
                  >
                    {cardDescription}
                  </p>
                )}
              </div>
            </a>
          </Link>
        ) : (
          <Link href={redirectUrl} passHref>
            <a
              className={`${styles.CardItem} px-20 py-12 font-semibold-sm lh-sm}`}
            >
              <span title={title} className="text-oxfordBlue">
                {title}
              </span>
            </a>
          </Link>
        )
      ) : cardType === "shop-by-business" ? (
        <Link href={redirectUrl} passHref>
          <a
            className={`${styles.CardItem} px-4 py-4 font-semibold-sm lh-sm}`}
          >
            <span title={title} className="text-oxfordBlue">
              {title}
            </span>
          </a>
        </Link>
      ) : (
        <Link href={redirectUrl} passHref>
          <a className={classNames(styles.CategoryItem, styles.BrandItem)}>
            <div>
              <div
                style={{ backgroundColor: backgroundColor }}
                className={`${styles.CategoryImage} mb-12 p-22`}
                key={title}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative"
                  }}
                >
                  <Image
                    src={cardImage ? cardImage?.url : ""}
                    alt={(cardImage && cardImage?.altText) || "card image"}
                    layout="fill"
                    objectFit="contain"
                  />
                </div>
              </div>
              <h2
                className={`${styles.CardTitle} font-semibold-h5 lh-sm text-oxfordBlue text-center`}
              >
                {title}
              </h2>
              {cardDescription !== "" && (
                <p
                  className="font-regular-xs lh-lg text-center text-oxfordBlue"
                  style={{ maxWidth: "200px" }}
                >
                  {cardDescription}
                </p>
              )}
            </div>
          </a>
        </Link>
      )}
    </>
  );
};

export default CategoryItem;
