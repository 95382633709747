import { BannerProps } from "./types";
import styles from "./styles.module.scss";
import parser from "html-react-parser";

const Banner = ({
  bgColor,
  mainText,
  subText,
  image,
  vendorImage,
  vendorName
}: BannerProps) => {
  return (
    <div
      style={{
        backgroundColor: bgColor
      }}
    >
      <div className={`${styles.BannerContainer} container pt-35 pb-30`}>
        <img
          src={image?.url}
          alt={image?.altText}
          className={styles.BannerImage}
        />
        <div className={`${styles.BannerContent}`}>
          <h2
            className={`${styles.BannerTitle} font-bolder-xl lh-1 text-oxfordBlue mb-8`}
          >
            {parser(mainText) || ""}
          </h2>
          <h3
            className={`${styles.BannerDesc} font-regular-base lh-lg`}
          >
            {subText}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Banner;
